/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */
}

.fc-list-view {
  border-width: 1px;
  border-style: solid;
}

/* table resets */
.fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */
}

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}

.fc-list-table tr:first-child td {
  border-top-width: 0;
}

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px;
}

.fc-list-heading td {
  font-weight: bold;
}

.fc-ltr .fc-list-heading-main {
  float: left;
}

.fc-ltr .fc-list-heading-alt {
  float: right;
}

.fc-rtl .fc-list-heading-main {
  float: right;
}

.fc-rtl .fc-list-heading-alt {
  float: left;
}

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */
}

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0;
}

.fc-rtl .fc-list-item-marker {
  padding-left: 0;
}

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit;
}

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline;
}

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee;
}